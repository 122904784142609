import Image from "next/image";
import React from "react";

const Companies = () => {
  return (
    <div className="max-w-screen-xl mx-auto justify-between pb-4 px-4 lg:px-16 my-12">
      <div className="container mx-auto pt-12">
        <div className="w-11/12 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto sm:mb-10">
          <h1
            tabIndex="0"
            className="focus:outline-none xl:text-4xl md:text-3xl text-2xl text-center text-gray-800 font-extrabold mb-8 dark:text-white"
          >
            We work with the most coveted brands
          </h1>
        </div>
        <div className="sm:py-12 px-15 flex flex-wrap">
          <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-b lg:border-b xl:border-r lg:border-r :border-r border-gray-200 xl:pb-10 pb-16 items-center">
            <Image
              src="/niva.png"
              alt="niva"
              width={140}
              height={140}
              className="grayscale hover:grayscale-0"
            />
          </div>
          <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-b lg:border-b xl:border-r lg:border-r border-gray-200 xl:pb-10 pb-16 items-center">
            <Image
              src="/hdfc.png"
              alt="hdfc"
              width={80}
              height={80}
              className="grayscale hover:grayscale-0"
            />
          </div>
          <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-b lg:border-b border-gray-200 xl:pb-10 pb-16 pt-4 items-center">
            <Image
              src="/tata.png"
              alt="tata"
              width={160}
              height={160}
              className="grayscale hover:grayscale-0"
            />
          </div>
          <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center lg:border-b xl:border-b lg:border-l xl:border-l border-gray-200 xl:pb-10 pb-16 items-center">
            <Image
              src="/star.png"
              alt="star"
              width={180}
              height={120}
              className="grayscale hover:grayscale-0"
            />
          </div>
          <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-r lg:border-r border-gray-200 xl:pt-10 items-center">
            <Image
              src="/iffco.svg"
              alt="iffco"
              width={140}
              height={140}
              className="grayscale hover:grayscale-0"
            />
          </div>
          <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-r lg:border-r border-gray-200 xl:pt-10 items-center">
            <Image
              src="/united.png"
              alt="united"
              width={100}
              height={100}
              className="grayscale hover:grayscale-0"
            />
          </div>
          <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:pt-10 lg:pt-10 md:pt-2 pt-16">
            <Image
              src="/lic.png"
              alt="lic"
              width={140}
              height={90}
              className="grayscale hover:grayscale-0"
            />
          </div>
          <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:border-l lg:border-l border-gray-200 lg:pt-10 md:pt-2 pt-16">
            <Image
              src="/max.png"
              alt="max"
              width={100}
              height={100}
              className="grayscale hover:grayscale-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Companies;
