import Image from "next/image";
import React from "react";

const Features = () => {
  return (
    <div className="max-w-screen-xl mx-auto my-10 md:my-12 flex flex-col justify-between px-4 lg:px-16">
      {/* Container for demo purpose */}
      <div className="mx-auto p-4 bg-gray-100 border dark:border-gray-900 rounded-md dark:bg-gray-600 dark:text-white">
        {/* Section: Design Block */}
        <section className="text-center">
          <h1 className="xl:text-4xl md:text-3xl text-2xl mb-4 font-extrabold">
            Your trusted insurance partner
            <span className="text-blue-600 dark:text-blue-600"></span>
          </h1>
          <div className="grid gap-x-6 sm:grid-cols-3 lg:gap-x-12">
            <div className="mb-6 md:mb-0">
              <div className="mb-2 inline-block rounded-md bg-primary-100 py-4 text-primary">
                <Image
                  src="/info.svg"
                  alt="Mail"
                  width={30}
                  height={30}
                  className="w-6 h-6 dark:filter dark:brightness-0 dark:invert"
                />
              </div>
              <h2 className="mb-2 text-xl font-bold">100%</h2>
              <p className="text-gray-700 dark:text-gray-200">
                Perfect claim-settlement ratio across insurance domains.
              </p>
            </div>
            <div className="mb-6 md:mb-0">
              <div className="mb-2 inline-block rounded-md bg-primary-100 py-4 text-primary">
              <Image
                  src="/rupee.svg"
                  alt="Mail"
                  width={30}
                  height={30}
                  className="w-6 h-6 dark:filter dark:brightness-0 dark:invert"
                />
              </div>
              <h2 className="mb-4 text-xl font-bold">1 Crore+</h2>
              <p className="text-gray-700 dark:text-gray-200">
                Total claim amount received by our customers across domains.
              </p>
            </div>
            <div className="mb-6 md:mb-0">
              <div className="mb-2 inline-block rounded-md bg-primary-100 py-4 text-primary">
              <Image
                  src="/group.svg"
                  alt="Mail"
                  width={30}
                  height={30}
                  className="w-6 h-6 dark:filter dark:brightness-0 dark:invert"
                />
              </div>
              <h2 className="mb-4 text-xl font-bold">1000+</h2>
              <p className="text-gray-700 dark:text-gray-200">
                Thousands of satisfied customers across the world.
              </p>
            </div>
          </div>
        </section>
        {/* Section: Design Block */}
      </div>
      {/* Container for demo purpose */}
    </div>
  );
};

export default Features;
